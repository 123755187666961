import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/page.js";
import { FaInfoCircle } from 'react-icons/fa';
import battleOfVimyImage from '../images/the-battle-of-vimy-ridge.jpg';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Figure = makeShortcode("Figure");
const LinkExternal = makeShortcode("LinkExternal");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Row mdxType="Row">
  <Column mdxType="Column">
    <p>
      The Battle of Vimy Ridge in northern France, April 9th to 12th, 1917 is
      considered to be one of the defining events in the history of our nation.
    </p>
    <p>
      The <strong>Vimy Oaks Legacy Corporation</strong> is a not-for-profit
      corporation. It is made up of volunteers promoting the memory and legacy
      of Canadians who fought in the First World War through the planting of
      “Vimy Oak” saplings that are descendants of acorns gathered after the
      Battle of Vimy Ridge and planted in Canada by a Canadian soldier,
      Lieutenant Leslie Miller.
    </p>
    <p>
      We have now fulfilled our two fold mission which was to create living
      memorials by:
    </p>
    <ol>
      <li>
        repatriating “Vimy Oak” saplings (French oaks – Quercus robur) to Vimy
        Ridge in 2018, and
      </li>
      <li>distributing commemorative “Vimy Oak” saplings across Canada.</li>
    </ol>
  </Column>
  <Column mdxType="Column">
    <Figure image={battleOfVimyImage} width={500} mdxType="Figure">
      The Vimy Ridge battlefield, northern France, April 1917 (by – Adam Moran,
      Victoria Goodwin and Sarah Toole)".
    </Figure>
  </Column>
    </Row>
    <hr></hr>
    <h2>{`Vimy Ridge Centennial Celebrations`}</h2>
    <p>{`For information about the Vimy Ridge Centennial celebrations that took place at
the Canadian National Vimy Memorial Historic Site, France, in April 2017, please
go to `}<LinkExternal href="http://www.vimyfoundation.ca/" mdxType="LinkExternal">{`The Vimy Foundation
website`}</LinkExternal>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      